import React from "react";
import Routes from "./routes";
import "./App.css";
import { ThemeProvider } from "styled-components";
import { theme, GlobalStyles, Footer } from "pubtrack-frontend-library";

const customTheme = {
  ...theme,

  primaryColor: '#011e41',
  secondaryColor: '#0043e0',

  accordionHeaderBackgroundColor: '#011e41',
  modalHeaderBackgroundColor: '#011e41',
  tabItemColor: '#fff',

  navbarLogoBackgroundColor: '#fff',
  navbarBackgroundColor: '#011e41',
  navbarButtonHoverColor: '#0043e0',
  navbarButtonTextColor: '#fff',
  navbarButtonActiveTextColor: "#fff",
  navbarFontWeight: 600,
  navbarButtonHoverTextColor: "#fff",
  logoutButtonBackgroundColor: '#0479a7',
  logoutButtonTextColor: "#fff",

  paginationButtonBackgroundColor: '#0043e0',
  paginationButtonHoverBackgroundColor: '#011e41',

  tableHeaderBackgroundColor: '#011e41',

  searchButtonBackgroundColor: '#0479a7',
  defaultButtonBackgroundColor: '#0479a7',
  primaryButtonBackgroundColor: '#0479a7',
  secondaryButtonBackgroundColor: '#0479a7',
  colorsPalette: [
    "#002242", "#144773", "#346ea4", "#4e8ab8", "#6faed2", "#9ecbe3"
  ]
}

const App = () => (
  <ThemeProvider theme={customTheme}>
    <GlobalStyles />
    <Routes />
    <Footer />
  </ThemeProvider>
);

export default App;