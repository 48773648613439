import { axiosInstance } from "./axiosSetup";
import { handleErrors } from "pubtrack-frontend-library";


export async function getInstitutionDetails(id) {
    return await axiosInstance
        .get(`tracker/reports/institution/articles?query=${id}`)
        .catch(handleErrors)
};

export async function getInstitutionJournals(id) {
    return await axiosInstance
        .get(`tracker/reports/articles/journals?ror=${id}`)
        .catch(handleErrors)
};

export async function getP1Messages() {
    return await axiosInstance
        .get(`tracker/reports/articles/messages`)
        .catch(handleErrors)
};

export async function getInstitutionArticlesPerMonth(id) {
    return await axiosInstance
        .get(`tracker/reports/lastyear/articles?ror=${id}`)
        .catch(handleErrors)
};

export async function getInstitutionArticles(rorId, type) {
    return await axiosInstance
        .get(`tracker/reports/institution/articles?ror=${rorId}&selection=${type}`)
        .catch(handleErrors)
};
