import React, { useEffect, useState } from "react";
import { Field, withFormik } from "formik";
import { getInstitutionArticles, getInstitutionJournals, getInstitutionArticlesPerMonth } from "../../helpers/institutions";
import {
    Table,
    Loader,
    ErrorMessage,
    AsyncInput,
    Card,
    BarChart,
    PieChart,
    ArticleDetailsModal,
    loadRorOptions,
    NavigationTabs
} from "pubtrack-frontend-library";
import { MDBCol, MDBRow } from 'mdbreact';
import { getArticleDetails } from "../../helpers/articles";
import { getArticlesPerInstitution } from "../../helpers/statistics";
import { userSettings } from "../../user-settings";
import { articlesPerInstitutionColumns, publishedArticlesColumns } from './columnsData'

const Institutions = () => {
    const [loading, setLoading] = useState(false);
    const [selectedInstitution, setSelectedInstitution] = useState(null);
    const [error, setError] = useState(false);
    const [institutionArticlesPerMonth, setInstitutionArticlesPerMonth] = useState(null);
    const [institutionJournals, setInstitutionJournals] = useState(null);
    const [articleDetails, setArticleDetails] = useState(null);
    const [articlesPerInstitution, setArticlesPerInstitution] = useState([]);
    const [publishedArticles, setPublishedArticles] = useState(null);
    const [selectedArticle, setSelectedArticle] = useState(null);
    const [isArticleOpen, setIsArticleOpen] = useState(false);


    useEffect(() => {
        setLoading(true);
        getArticlesPerInstitution()
            .then(res => {
                setArticlesPerInstitution(res.data);
                setLoading(false);
            })
            .catch(() => setError(true))
            .finally(() => setLoading(false));
    }, [])

    useEffect(() => {
        if (selectedInstitution) {
            setLoading(true);

            const fetchData = async () => {
                try {
                    const [
                        institutionArticlesPerMonthResponse,
                        institutionJournalsResponse,
                        inProductionArticlesResponse
                    ] = await Promise.all([
                        getInstitutionArticlesPerMonth(encodeURIComponent(selectedInstitution)),
                        getInstitutionJournals(encodeURIComponent(selectedInstitution)),
                        getInstitutionArticles(encodeURIComponent(selectedInstitution), 'published')
                    ])
                    setInstitutionArticlesPerMonth(institutionArticlesPerMonthResponse.data)
                    setInstitutionJournals(institutionJournalsResponse.data);
                    setPublishedArticles(inProductionArticlesResponse.data);
                } catch (error) {
                    setError(true);
                } finally {
                    setLoading(false);
                }
            };
            fetchData();
        }
    }, [selectedInstitution]);

    useEffect(() => {
        if (selectedArticle) {
            getArticleDetails(selectedArticle)
                .then(res => setArticleDetails(res.data))
                .then(setIsArticleOpen(true))
                .then(setSelectedArticle(null))
        }
    }, [selectedArticle]);

    const handleSelectInstitution = row => {
        const rorId = row && row.original ? row.original["ror"] : null;
        setSelectedInstitution(rorId);
    };

    const handleSelectArticle = row => {
        const articleId = row && row.original ? row.original.id : null;
        setSelectedArticle(articleId);
    };

    const tabs = [
        {
            id: '1',
            title: 'Published',
            content: (
                <div>
                    {publishedArticles && (
                        <Table
                            data={publishedArticles}
                            columns={publishedArticlesColumns}
                            onSelectRow={handleSelectArticle}
                        />
                    )}
                </div>
            ),
        },
    ];


    return (
        <div className="container">
            <div className="mt-5 pb-3">
                <div className="d-flex justify-content-end mb-4">
                    <Field
                        component={AsyncInput}
                        loadOptions={loadRorOptions}
                        setValue={setSelectedInstitution}
                        placeholder="Select institution"
                    />
                </div>

                {!selectedInstitution && !loading && !error && (
                    <div className="my-5">
                        <Table
                            title="Articles per institution"
                            data={articlesPerInstitution}
                            columns={articlesPerInstitutionColumns}
                            onSelectRow={handleSelectInstitution}
                        />
                    </div>
                )}
                {loading && <Loader />}
                {!loading && error && <ErrorMessage />}
                {!loading && selectedInstitution && (
                    <>
                        {
                            institutionArticlesPerMonth && institutionJournals &&
                            <MDBRow className="mt-4">
                                <MDBCol md='7' className="mb-5 mt-4">
                                    <Card
                                        title="Institution articles per month:"
                                        content={<BarChart data={institutionArticlesPerMonth} xAxisKey="month" yAxisKey="count" />}
                                    />
                                </MDBCol>
                                <MDBCol md='5' className="mb-5 mt-4">
                                    <Card
                                        title="Institution journals:"
                                        content={<PieChart data={institutionJournals} labelKey="name" valueKey="count" />}
                                    />
                                </MDBCol>
                            </MDBRow>
                        }
                        <NavigationTabs tabs={tabs} />
                    </>
                )}

                <ArticleDetailsModal
                    isOpen={isArticleOpen}
                    setIsOpen={setIsArticleOpen}
                    articleDetails={articleDetails}
                    tabItems={userSettings.details}
                />
            </div>
        </div>
    );
};


export default withFormik({})(Institutions);