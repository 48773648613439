import axios from 'axios';
import { ApiBaseUrl, ApiPubtrackPath, ApiAuthUrl } from '../constants';
import { token as TOKEN } from "pubtrack-frontend-library";


const axiosInstance = axios.create({
    baseURL: `${ApiBaseUrl}${ApiPubtrackPath}`
});

const axiosAuthInstance = axios.create({
    baseURL: `${ApiAuthUrl}${ApiPubtrackPath}`
});

axiosInstance.interceptors.request.use((config) => {
    const token = localStorage.getItem(TOKEN);
    config.headers.Authorization = `Bearer ${token}`;
    config.headers["Content-Type"] = 'application/json';
    return config;
});

axiosAuthInstance.interceptors.request.use((config) => {
    const token = localStorage.getItem(TOKEN);
    config.headers.Authorization = `Bearer ${token}`;
    config.headers["Content-Type"] = 'application/json';
    return config;
});

export { axiosInstance, axiosAuthInstance };